import { QRCodeCanvas } from "@cheprasov/qrcode";

const foundBarcodes = document.querySelectorAll("[id*='line_item_barcode']");

for (var i = 0, len = foundBarcodes.length; i < len; i++) {
  const barcode = foundBarcodes[i];
  const barcodeVal = barcode.id.split("-")[1];

  const qrSVG = new QRCodeCanvas(barcodeVal, {
    level: "Q",
    padding: 2,
  });
  const dataUrlWithQRCode = qrSVG.toDataUrl();
  barcode.src = dataUrlWithQRCode;
}
